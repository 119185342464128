<template>
    <client-page>
        <page-section titAlign="text-center" class="page-section--first page-section--last">
            <template slot="tit">회원가입</template>

            <div class="mw-580px mx-auto">
                <div v-if="tab != 'result'" class="tabs-wrap">
                    <v-btn-toggle v-model="form.type" mandatory group class="grey darken-4" @change="emit">
                        <v-btn class="v-size--xx-large w-50" :value="USER_TYPES.PERSON.value" v-bind="{ loading }">개인회원</v-btn>
                        <v-btn class="v-size--xx-large w-50" :value="USER_TYPES.COMPANY.value" v-bind="{ loading }">기업회원</v-btn>
                    </v-btn-toggle>
                </div>

                <v-tabs-items v-model="tab" touchless>
                    <!-- 회원가입정보입력 -->
                    <v-tab-item value="form">
                        <v-form v-model="isValid">
                            <div class="txt txt--xs text-right pb-16px pb-md-20px"><span class="red--text">* </span>필수입력 항목입니다.</div>
                            <vertical-form-table v-model="form" v-bind="{ items }" @certification="(value) => (form._certification = value)">
                                <template slot="비밀번호 확인">
                                    <v-password-confirm-field :password="form.password" placeholder="동일한 비밀번호를 재입력하세요." />
                                </template>
                                <template slot="주소">
                                    <v-address-field v-model="form" outlined persistent-placeholder :btnAttrs="{ large: true, color: 'primary' }" />
                                </template>
                            </vertical-form-table>

                            <div class="pt-30px pt-md-40px">
                                <terms-of-agreements-short v-model="form._terms" @isValid="(isValid) => (isTermsValid = isValid)" />
                            </div>

                            <div class="btn-wrap btn-wrap--lg">
                                <v-row justify="center" class="row--sm">
                                    <v-col cols="6" sm="auto">
                                        <v-btn v-bind="{ ...btn_tertiary, ...$attrs, loading }" class="v-size--xx-large w-100 min-w-sm-200px" @click="$router.go(-1)">취소하기</v-btn>
                                    </v-col>
                                    <v-col cols="6" sm="auto">
                                        <v-btn v-bind="{ ...btn_primary, ...$attrs, loading, disabled }" class="v-size--xx-large w-100 min-w-sm-200px" @click="join">회원가입하기</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-tab-item>
                    <!-- 회원가입완료 -->
                    <v-tab-item value="result">
                        <div class="text-center pt-30px pb-20px">
                            <div class="mb-18px mb-md-24px">
                                <icon-check />
                            </div>
                            <tit-wrap-subtitle titAlign="text-center">
                                <template slot="tit">회원가입이 완료되었습니다.</template>
                                <template slot="txt">대시보드에서 추가 정보를 입력해 주세요.</template>
                            </tit-wrap-subtitle>

                            <div class="btn-wrap">
                                <v-row justify="center" class="row--sm">
                                    <v-col cols="6" sm="auto">
                                        <v-btn v-bind="{ ...btn_primary, ...$attrs }" to="/mypage" class="v-size--xx-large w-100 min-w-sm-200px">MY대시보드로 이동</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import Axios from "axios";
import CryptoAES from "@/plugins/crypto-aes";
import { mapActions } from "vuex";
import { initUser, rules, USER_TYPES } from "@/assets/variables";
import { btn_primary, btn_tertiary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";
import TermsOfAgreementsShort from "@/components/client/join/terms-of-agreements-short.vue";
import IconCheck from "@/components/client/icons/icon-check.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapSubtitle,
        VAddressField,
        VerticalFormTable,
        VPasswordConfirmField,
        TermsOfAgreementsShort,
        IconCheck,
    },
    data: () => ({
        btn_primary,
        btn_tertiary,

        form: initUser(),

        tab: "form",
        // tab: "result",

        USER_TYPES,

        loading: false,
        isValid: false,
        isTermsValid: undefined,
    }),
    computed: {
        promoterCode() {
            return this.$route.query.promoterCode || null;
        },
        accessToken() {
            return this.$route.query.accessToken;
        },
        isSnsJoin() {
            return !!this.accessToken;
        },
        disabled() {
            return !this.isValid || !this.isTermsValid;
        },
        items() {
            const items = [
                {
                    key: "username",
                    term: "아이디",
                    type: "text",
                    required: true,
                    placeholder: "아이디를 입력하세요",
                    rules: rules.username,
                },
                {
                    key: "password",
                    term: "비밀번호",
                    type: "password",
                    required: true,
                    placeholder: "비밀번호를 입력하세요",
                    rules: rules.password,
                    hides: this.isSnsJoin,
                },
                {
                    key: "password",
                    term: "비밀번호 확인",
                    required: true,
                    placeholder: "동일한 비밀번호를 재입력하세요.",
                    hides: this.isSnsJoin,
                },
                {
                    key: "name",
                    term: "이름",
                    type: "text",
                    required: true,
                    placeholder: "이름을 입력하세요.",
                    rules: rules.name,
                },
                {
                    key: "email",
                    term: "이메일",
                    type: "email",
                    required: true,
                    placeholder: "이메일을 입력하세요.",
                    rules: rules.email,
                },
                {
                    key: "phone",
                    term: "연락처",
                    type: "phone",
                    required: true,
                },
                {
                    key: "businessRegistration",
                    term: "사업자등록증",
                    type: "file",
                    required: true,
                    hides: this.form.type != USER_TYPES.COMPANY.value,
                },
                {
                    key: "referrer",
                    term: "추천인 코드",
                    type: "text",
                },
            ];

            return items.filter(({ hides }) => !hides).map((item) => ({ ...item, outlined: true }));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        accessToken() {
            this.init();
        },
    },
    methods: {
        ...mapActions("metaPixel", ["fbq"]),
        ...mapActions("naverWcs", ["wcs_do_cnv"]),
        async init() {
            let { promoterCode } = this;

            if (this.accessToken) {
                if (this.loading) return;
                else this.loading = true;

                try {
                    const { user = {} } = (await Axios.get("/api/v1/me/info", { headers: { Authorization: `Bearer ${this.accessToken}` } }))?.data || {};

                    let { _id, name, email, birthday, sex, phone } = user;
                    birthday = birthday?.toDate?.() || birthday;

                    this.form = initUser({ _id, name, email, birthday, sex, phone, promoterCode });
                } catch (error) {
                    this.$handleError(error);
                    this.$router.replace({ path: "/join" });
                } finally {
                    this.loading = false;
                }
            } else {
                this.form = initUser({ promoterCode });
            }
        },

        emit() {
            this.form = initUser(this.form);
        },

        async join() {
            if (!this.validates()) return;
            if (this.loading) return;
            this.loading = true;

            try {
                let { _businessRegistration, businessRegistration, ...form } = this.form;
                form.password = CryptoAES.encrypt(this.form.password);

                // POST, PUT user && POST login
                if (this.isSnsJoin) {
                    const { accessToken } = this;
                    delete form.password;
                    try {
                        await Axios.put("/api/v1/me/info", form, { headers: { Authorization: `Bearer ${accessToken}` } });
                    } catch (error) {
                        this.$handleError(error);
                        throw new Error();
                    }
                    await this.$store.dispatch("login", { accessToken });
                } else {
                    await api.v1.users.post(form);
                    await this.$store.dispatch("login", { username: this.form.username, password: this.form.password });
                }

                // POST resource
                if (form.type == USER_TYPES.COMPANY.value) {
                    if (businessRegistration instanceof File) {
                        businessRegistration = (await api.v1.files.post({ path: "businessRegistration" }, businessRegistration))?.file;
                    }
                    _businessRegistration = businessRegistration?._id || null;
                    await api.v1.me.put({ _businessRegistration });
                }

                this.tab = "result";

                this.fbq({ type: "CompleteRegistration" });
                this.wcs_do_cnv({ type: "2" });
            } finally {
                this.loading = false;
            }
        },

        validates() {
            try {
                switch (this.isTermsValid) {
                    case undefined:
                        throw new Error("이용약관을 확인해주세요");
                    case false:
                        throw new Error("필수 이용약관에 모두 동의해주세요");
                    default:
                        break;
                }
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.v-btn-toggle {
    width: 100%;
    border-radius: var(--btn-border-radius-xxl);
    overflow: hidden;
    > .v-btn.v-btn {
        margin: 0;
        border-radius: 0;
        opacity: 1;
        border-width: var(--btn-outline-border-width);
        background-color: #fff !important;
        border-color: inherit;
        &--active {
            color: #fff !important;
            background-color: inherit !important;
            &:before {
                opacity: 0;
            }
        }
        &.v-size--xx-large {
            &:first-child {
                border-top-left-radius: var(--btn-border-radius-xxl);
                border-bottom-left-radius: var(--btn-border-radius-xxl);
            }
            &:last-child {
                border-top-right-radius: var(--btn-border-radius-xxl);
                border-bottom-right-radius: var(--btn-border-radius-xxl);
            }
        }
    }
}
</style>
